import { Autocomplete, useJsApiLoader } from "@react-google-maps/api";
import React, { useState } from "react";
import { Form } from "react-bootstrap";

export const COUNTY_LIST = [
    'Harjumaa',
    'Hiiumaa',
    'Ida-Virumaa',
    'Jõgevamaa',
    'Järvamaa',
    'Läänemaa',
    'Lääne-Virumaa',
    'Põlvamaa',
    'Pärnumaa',
    'Raplamaa',
    'Saaremaa',
    'Tartumaa',
    'Valgamaa',
    'Viljandimaa',
    'Võrumaa'
];

interface AddressAutocompleteProps {
    disabled: boolean;
    address?: string;
    setAddress: (address: {
        address?: string;
        fullAddress?: string;
        city?: string;
        county?: string;
        zip?: string;
    }) => void;
    required?: boolean
}


export default function AddressAutocomplete({ address, setAddress, disabled, required = true}: AddressAutocompleteProps) {
    
    const [autocomplete, setAutocomplete] = useState<any>(undefined);
    const { isLoaded } = useJsApiLoader({
        googleMapsApiKey: process.env.REACT_APP_MAPS_API_KEY || '',
        libraries: ['places']
    })

    const onAutocompleteLoad = (ac: any) => {
        setAutocomplete(ac);
    }

    const getAddressComponent = (addressComponents: any, key: string) => {
        return addressComponents?.find((c: any) => c.types.indexOf(key) !== -1)?.long_name || '';
    }

    const onPlaceChanged = () => {
        if (autocomplete) {
            const place = autocomplete.getPlace();
            const city = getAddressComponent(place.address_components, 'locality')
            const county = getAddressComponent(place.address_components, 'administrative_area_level_1');
            const countyFromArray = COUNTY_LIST.find(c => c.indexOf(county.split(' ')[0]) !== -1) || county;
            const zip = getAddressComponent(place.address_components, 'postal_code');

            setAddress({
                address: address,
                fullAddress: place.formatted_address,
                city,
                county: countyFromArray,
                zip
            });
        }
    }

    if (!isLoaded) {
        return null;
    }

    return (
        <Autocomplete
            onLoad={onAutocompleteLoad}
            onPlaceChanged={() => onPlaceChanged()}
            fields={['address_components', 'formatted_address']}
            restrictions={{ country: 'EE' }}
        >
            <Form.Control 
                required={required}
                value={address}
                disabled={disabled}
                onChange={(e) => setAddress({
                    address: e.target.value
                })}
                type="text"
                autoComplete="street-address"
                placeholder="Sisesta aadress"
            />
        </Autocomplete>
    );
}