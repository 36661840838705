/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { ElectricityAgreementForm, MeteringPoint, SimplifiedElectricityAgreement } from "../../models";
import moment from "moment";
import { getEarliestPossibleAgreementStartDate } from "helpers";
import MeteringPointService from "services/electricity/electricity-metering-points.service";

interface NewElectricityAgreementMeteringPointsProps {
    meteringPoints: MeteringPoint[];
    setMeteringPoints: (meteringPoints: MeteringPoint[]) => void;
    form: ElectricityAgreementForm;
    setForm: (form: ElectricityAgreementForm) => void;
}

export default function NewElectricityAgreementMeteringPoints(
    { meteringPoints, setMeteringPoints, form, setForm }: NewElectricityAgreementMeteringPointsProps) {

    const [meteringPointsLoaded, setMeteringPointsLoaded] = useState(false);
    const [error, setError] = useState("");

    useEffect(() => {
        setMeteringPointsLoaded(false);
        setMeteringPoints([]);
    }, [form.customerId])

    const onEleringRequest = () => {
        setError("");
        setMeteringPointsLoaded(false);
        if (form.customerId) {
            MeteringPointService.getMeteringPointsExistingCustomer(form.customerId)
                .then(res => {
                    setMeteringPoints(res.meteringPoints);
                    setMeteringPointsLoaded(true);
                })
        } else if (form.registryNumber) {
            const name = form.isCompany ? form.customerName : form.firstName + ' ' + form.lastName;
            MeteringPointService.getMeteringPointsNewCustomer(form.registryNumber, name || '')
                .then(res => {
                    setMeteringPoints(res.meteringPoints);
                    setMeteringPointsLoaded(true);
                }).catch(() => setError("Mõõtepunktide päring ebaõnnestus"))
        }
    }

    function startDateInFuture(date: string){
        return moment(date).isAfter(moment())
    }

    function meteringPointIsValid(meteringPoint: MeteringPoint){
        if (!meteringPoint.relatedAgreement)
            return true;

        const startDate = meteringPoint.relatedAgreement.startDate;
        if (startDate != null)
            return true;

        return false;
    }

    function getWarningMessage(relatedAgreement : SimplifiedElectricityAgreement | undefined){
        if (!relatedAgreement)
            return null

        if (relatedAgreement.startDate == null)
            return <div className="font-weight-normal text-danger">Mõõtepunkt on juba seotud elektrilepinguga, mis on kinnitamise ootel!</div>;

        switch (startDateInFuture(relatedAgreement.startDate)) {
            case true:
                return <div className="font-weight-normal text-warning">Mõõtepunkt on juba seotud tulevikus algava lepinguga!</div>
            case false:
                return <div className="font-weight-normal text-warning">Mõõtepunkt on juba seotud aktiivse elektrilepinguga!</div>;
        }
    }

    function onChange(eic: string){
        const updatedMeteringPoints = meteringPoints.map(m => m.eic === eic ? ({...m, checked: !m.checked }) : m)

        setMeteringPoints(updatedMeteringPoints)

        const startDate = getEarliestPossibleAgreementStartDate(updatedMeteringPoints);
        const isJointInvoicePossible = updatedMeteringPoints.filter(mp => mp.checked).some(mp => mp.hasMatchingGridOperator)

        const hasJointInvoicePackageSelected = !!form.consumptionPackage.id && form.consumptionPackage.jointInvoicePackage;

        setForm({
            ...form,
            startDate,
            endDate: moment(startDate).add(3, "M").toDate(),
            isJointInvoicePossible,
            consumptionPackage: !isJointInvoicePossible && hasJointInvoicePackageSelected ? {} : form.consumptionPackage
        })
    }
        
    const tableRows = meteringPoints.map(mp => {
    const isDisabled = !meteringPointIsValid(mp);

    return (
        <tr key={mp.eic}>
            <td>
                <Form.Check
                    id={'check-' + mp.eic}
                    custom
                    type="checkbox"
                    className='checkbox-large'
                    label=''
                    onChange={() => onChange(mp.eic)}
                    checked={!!mp.checked}
                    disabled={isDisabled}
                />
            </td>
            <td className={isDisabled ? 'text-gray-600' : ''}>
                {mp.eic}
            </td>
            <td className={isDisabled ? 'text-gray-600' : ''}>
                { mp.address ?? 
                <Form.Control
                    type="text"
                    value={mp.newAddress}
                    disabled={isDisabled}
                    className={(form.validated && mp.checked && !mp.address && !mp.newAddress ? ' is-invalid' : '')} 
                    onChange={(e) => setMeteringPoints(meteringPoints.map(m => m.eic === mp.eic ? ({...m, newAddress: e.target.value }) : m))}
                />}
                {getWarningMessage(mp.relatedAgreement)}
                {mp.terminationFee && (
                    <div className="font-weight-normal text-warning">Kehtiva lepingu lõpetamine on tasuline!</div>
                )}
            </td>
            <td>
            {
                form.sellCheck && (
                    <Form.Control
                        value={mp.productionCapacity}
                        onChange={(e) => setMeteringPoints(meteringPoints.map(m => m.eic === mp.eic ? ({...m, productionCapacity: isNaN(Number(e.target.value)) ? undefined : Number(e.target.value) }) : m))}
                        type="number"
                        disabled={isDisabled}
                    />
                )
            }
            </td>
        </tr>
        )
    });

    const allMeteringPointsChecked = !meteringPoints.some(mp => !mp.checked && meteringPointIsValid(mp));

    return (
        <>
            {tableRows.length ? <table className="new-electricity-agreement__metering-point-table">
                <thead>
                    <tr>
                        <th>
                            <Form.Check
                                id='check-all'
                                custom
                                type="checkbox"
                                className='checkbox-large'
                                label=''
                                onChange={() => setMeteringPoints(
                                    meteringPoints.map(mp => ({...mp, checked: meteringPointIsValid(mp) ? !allMeteringPointsChecked : mp.checked }))
                                    )}
                                checked={allMeteringPointsChecked}
                            />
                        </th>
                        <th>EIC</th>
                        <th>AADRESS</th>
                        {
                            form.sellCheck && (
                                <th>TOOTMISVÕIMSUS(kW)</th>
                            )
                        }
                    </tr>
                </thead>
                <tbody>
                    { tableRows }
                </tbody>
            </table> : 
            <>
            { meteringPointsLoaded ?
                <div className="alert alert-info d-flex align-items-center text-dark"><i className='alert-icon icon__info--masked bg-dark mr-2'></i>Teil puuduvad mõõtepunktid.</div>
                : null
            }
            <button type="button" className="btn btn-outline-primary" onClick={onEleringRequest}>Tee päring Eleringi</button>
            {
                error ? 
                    <div className="text-danger mt-1">
                        Mõõtepunktide pärimine ebaõnnestus
                    </div> 
                : null
            }
            </>
            }
        </>
    )
}